.MyGrid{      
    border: solid 0px #ddd;
    padding: 1px;      
}

.MyRow{      
    border: solid 0px #ddd;
    margin: 0px 0px;
    padding: 0px;  
    margin-left: 0;
  margin-right: 0;   
  
}

.MyCol{      
  border: solid 0px red;   
  padding: 0px;   
  display: flex;   
}

.MyButton{    
  height:50px;   

  text-overflow: ellipsis;
  display: flex;
  flex-wrap: wrap;
  white-space: normal !important;
   
  }



.MyItem{      
  border-style: none;
  border: none;   
  padding: 0px;        
    
}

.MyLabel{      
  border-style: none;
  border: none;   
  padding: 0px;  
  font-size: 4em      
    
}

.MyCard {  
  height: 30vh;  
  border-style: none;
  border: none;   
  margin: 2px 1px;      
  box-shadow: 0 0px 5px rgba(1, 0, 0, 0.3); 
  }


  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    .MyCard {  
      height: 25vh;  
      border-style: none;
      border: none;   
      margin: 2px 1px;      
      box-shadow: 0 0px 5px rgba(1, 0, 0, 0.3); 
      }
    }


  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    .MyCard {  
      height: 25vh;  
      border-style: none;
      border: none;   
      margin: 2px 1px;      
      box-shadow: 0 0px 5px rgba(1, 0, 0, 0.3); 
      }
    }


 
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3){
      /* Styles */
      .MyCard {  
        height: 31vh;  
        border-style: none;
        border: none;   
        margin: 2px 1px;      
        box-shadow: 0 0px 5px rgba(1, 0, 0, 0.3); 
        }
      }
  
      @media only screen and (min-device-width: 414px) and (max-device-height: 823px) and (-webkit-device-pixel-ratio: 3){
        /* Styles */
        .MyCard {  
          height: 23vh;  
          border-style: none;
          border: none;   
          margin: 2px 1px;      
          box-shadow: 0 0px 5px rgba(1, 0, 0, 0.3); 
          }
        }

    .MyBadge {   
     
      top: 0.1rem;
      right: 0.5rem;      
      position: absolute;
     border-radius: 100%;
  }

  .MyNewCard {
    width:100% ;
    height: 30vh; 
    margin-left: 0;
    margin-right: 1;    
  }

  .MyCardConfirm {
    width:100% ;
    height: 65vh; 
    margin-left: 0;
    margin-right: 1; 
  }

  .MyCardCheckOut {
    width:100% ;    
    margin-left: 0;
    margin-right: 1; 
  }
  .MyImage {   
    margin-top: 10px;
    width: 100%;  
  }

  .MyImageList {   
    margin-top: -50px;
    width: 100%;  
  }
  .MyList {   
    margin-top: -20px;
    padding-bottom: 40px;
    width: 100%;
  
  }



  .flex-content {
    margin: 0px auto;
    width: 100%;
    height: 100%;
    display: flex; /* Magic begins */
    flex-direction: column;
    justify-content: center; /* Center in main axis */
    align-items: center; /* Center in cross axis */
    --background-size: cover
  }
  #photo-fullscreen {
    max-width: 100%;
    max-height: 100%
  }

  .background{     
       
    margin: auto;   
   z-index: 1;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  
}

.CenterScreen {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
 }

 .center {
  display: block;
  margin-left: auto;
  margin-right: auto;  
}
  
.backgroundText {
  background: transparent !important;
}
  
.ion-toolbar {
  background: transparent; 
  }

  .fullscreen {  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .MyDisplayLeft {      
    float:left;   
    margin-left: 20px !important  
  }

  .MyDisplayRight {      
    float:right; 
    margin-right: 35px !important      
  }

  .MyDisplayRight2 {      
    float:right; 
    margin-right: 2px !important      
  }

  .ImageContainer {
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
  }
  
  .ImageText {
    position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  height:60px;
  padding: 1px;
  }

  .ImageTextMain {
    position: absolute;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  height:230px;

  padding: 17px;
  bottom: 15%;
  }

  .ImageTextTop {
    position: absolute;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  top: 0%;
  height:50px;
  padding: 18px;
  }

  .ImageTextMainBottom {
    position: absolute;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  bottom: 0%;
  }


  .ImageTextRight {
    position: absolute;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1; 
  width: 100%;
    top: 8px;
  right: 16px;
  }
  
  .ImageTextCenter {
    position: absolute;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1; 
  width: 100%;
  height:50px;
  padding: 5px;
  }
  

  .ImageTextBottom {
    position: absolute;
    color:red;  
    bottom: 10px; 
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
    color: #f1f1f1;    
    width: 100%;
    top:1%;    
    height:30px;
    padding: 6px;
  
  }


  .ModalPopup { 
    overflow: hidden;  
    height: 100%;
      max-width: 100%;   
  
  }

  